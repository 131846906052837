<!-- 分类列表 -->

<template>
  <div class="category">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="categoryValue" placeholder="搜索分类名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" row-key="categoryId" max-height="540">
          <el-table-column prop="categoryName" label="分类名称">
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      categoryValue: '', // 请搜索厂家
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created () {
    this.getCategoryInfoList()
  },
  methods: {
    // 获取商品列表
    getCategoryInfoList () {
      this.loading = true;
      this.$axios.get(this.$api.getCategoryInfoList, {
        params: {
          categoryName: this.categoryValue || null,  // 请搜索分类
          auditStatus: '1',
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 清空
    clearData () {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch () {
      this.clearData();
      this.getCategoryInfoList();
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.clearData();
      this.pageSize = e;
      this.getCategoryInfoList();
    },
    // 换页
    handleCurrentChange (e) {
      this.tableData = [];
      this.currentPage = e;
      this.getCategoryInfoList();
    },

  }

}



</script>

<style scoped lang="scss">
.category {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
      margin-left: 20px;
    }

    .el-select,
    .el-cascader,
    .el-button {
      margin-left: 20px;
    }

  }
}
</style>
